<template>
  <div style="margin-top: 200px">
    <div class="w600 pointer pa-5 relative-center">
      <v-file-input
        accept="text/csv"
        label="リストCSVアップロード"
        @change="uploadFile"
        outlined
        v-if="uploading"
      ></v-file-input>
    </div>

    <div class="text-center" v-show="load">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      <div>アップロード中...</div>
    </div>

    <hr />

    <v-btn color="orange" class="my-10" dark large @click="csvLoad"
      >アップロードCSV一覧</v-btn
    >
    <div class="mb-10">60ファイルまで保存</div>
    <v-row v-for="f in files" :key="f" class="w600 relative-center">
      <v-col cols="6">
        <p class="relative t-5">
          {{ f.replace("csv/", "").replace("：", ":") }}
        </p>
      </v-col>
      <v-col cols="6">
        <a
          :href="'/estate/csv/download/' + f.replace('csv/', '')"
          target="_blank"
        >
          <v-btn color="blue" outlined>ダウンロード</v-btn>
        </a>
      </v-col>
    </v-row>
    <Snackbar ref="snack" />
  </div>
</template>

<script>
import Snackbar from "@/components/snackbar.vue";
import axios from "axios";
import d from "@/d";

export default {
  data() {
    return {
      estates: "",
      show: 0,
      load: 0,
      files: [],
      uploading: true,
    };
  },
  components: {
    Snackbar,
  },
  methods: {
    async uploadFile(val) {
      if (val == null) {
        return false;
      }

      this.load = 1;
      let formData = new FormData();

      formData.append("file", val);
      const response = await axios.post("/estate/uploadFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      await d.sleep(1000);
      if (response.data.ok) {
        axios.post("/estate/upload");
        await d.sleep(3000);
        this.load = 0;
        this.$refs.snack.snack = true;
        this.$refs.snack.message = "アップロードが完了しました";
        this.$refs.form.reset();
      } else {
        this.load = 0;
        alert("アップロード失敗");
      }
    },

    async csvLoad() {
      const response = await axios.post("/estate/csv/load");

      this.files = response.data.files;
    },
  },
};
</script>